import * as RadixDialog from '@radix-ui/react-dialog';

import { ActionSheetContent, ActionSheetContentProps } from './ActionSheetContent';
import { ActionSheetOverlay } from './ActionSheetOverlay';

export type ActionSheetProps = Omit<ActionSheetContentProps, 'title'> & {
  onClose: () => void;
};

export const ActionSheet = ({ onClose, ...restOfProps }: ActionSheetProps) => {
  return (
    <RadixDialog.Root open onOpenChange={onClose}>
      <RadixDialog.Portal>
        <ActionSheetOverlay />
        <ActionSheetContent onClose={onClose} {...restOfProps} />
      </RadixDialog.Portal>
    </RadixDialog.Root>
  );
};
