import { tailwindMerge } from '@air/tailwind-variants';
import { memo, ReactNode } from 'react';

import { StatusAvatar } from '~/components/FileStatusTrackingPane/FileStatusTrackingPaneHeader/StatusAvatar';
import {
  TrackingPaneHeaderButtons,
  TrackingPaneHeaderButtonsProps,
} from '~/components/FileStatusTrackingPane/FileStatusTrackingPaneHeader/TrackingPaneHeaderButtons';
import { PaneItem } from '~/components/FileStatusTrackingPane/PaneItem';
import { StatusTrackingSubtitle } from '~/components/FileStatusTrackingPane/StatusTrackingSubtitle';
import { StatusTrackingTitle } from '~/components/FileStatusTrackingPane/StatusTrackingTitle';
import { UPLOADER_TITLE } from '~/constants/testIDs';

export enum SimpleStatus {
  completed = 'completed',
  paused = 'paused',
  errored = 'errored',
  progressing = 'progressing',
}

export interface UploaderPaneHeaderProps extends TrackingPaneHeaderButtonsProps {
  title: ReactNode;
  subtitle: ReactNode;
  disableCancel?: boolean;
  status: SimpleStatus;
  className?: string;
}

export const FileStatusTrackingPaneHeader = memo(
  ({
    isUploaderOpen,
    onToggle,
    status,
    title,
    subtitle,
    disableCancel,
    onCancel,
    onDismiss,
    onPause,
    onResume,
    className,
  }: UploaderPaneHeaderProps) => {
    return (
      <PaneItem
        className={tailwindMerge('px-4 py-3', isUploaderOpen ? 'rounded-none' : 'rounded', className)}
        avatar={<StatusAvatar status={status} />}
        title={
          <StatusTrackingTitle data-testid={UPLOADER_TITLE} className="font-semibold">
            {title}
          </StatusTrackingTitle>
        }
        subtitle={
          <StatusTrackingSubtitle className="truncate" data-testid="UPLOADER_SUBTITLE">
            {subtitle}
          </StatusTrackingSubtitle>
        }
        buttons={
          <TrackingPaneHeaderButtons
            status={status}
            onCancel={onCancel}
            onResume={onResume}
            disableCancel={disableCancel}
            onPause={onPause}
            isUploaderOpen={isUploaderOpen}
            onDismiss={onDismiss}
            onToggle={onToggle}
          />
        }
      />
    );
  },
);

FileStatusTrackingPaneHeader.displayName = 'FileStatusTrackingPaneHeader';
