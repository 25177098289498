import { Check as CheckIcon } from '@air/next-icons';
import { ReactNode, useMemo } from 'react';

import { DropdownMenu, type DropdownMenuProps } from './DropdownMenu';
import { type DropdownMenuOption, type DropdownMenuOptionItem, renderDropdownItems } from './utils/renderDropdownItems';

export type SelectDropdownMenuSelectedOption = Pick<DropdownMenuOption, 'id'> &
  Partial<Omit<DropdownMenuOptionItem, 'id'>>;

export type SelectDropdownMenuOption = DropdownMenuOption & { canSelect?: boolean };

export type SelectDropdownMenuProps = Omit<DropdownMenuProps, 'onSelect' | 'options' | 'trigger'> & {
  options?: SelectDropdownMenuOption[];
  onSelect?: (option: Pick<DropdownMenuOption, 'id'> & Partial<Omit<DropdownMenuOptionItem, 'id'>>) => void;
  /**
   * @TODO - In a separate PR, update this to be required
   */
  selectedOption?: SelectDropdownMenuSelectedOption;
  /**
   * @TODO - In a separate PR, update this so the function param is required
   */
  trigger: DropdownMenuProps['trigger'] | ((selectedOption?: SelectDropdownMenuSelectedOption) => ReactNode);
};

export const SelectDropdownMenu = ({
  onSelect,
  options,
  selectedOption,
  size,
  trigger,
  ...restOfProps
}: SelectDropdownMenuProps) => {
  const optionsWithSelect = useMemo(
    () =>
      options?.map((option) => {
        if (option.type === 'item') {
          const isOptionSelected = option.id === selectedOption?.id;

          return {
            ...option,
            onSelect: () => {
              option?.onSelect?.();

              if (option.canSelect) {
                onSelect?.(option);
              }
            },
            suffix: isOptionSelected ? <CheckIcon className="size-4 fill-jay-500" /> : option.suffix,
          };
        }

        return option;
      }),
    [onSelect, options, selectedOption],
  );

  return (
    <DropdownMenu
      size={size}
      trigger={typeof trigger === 'function' ? trigger(selectedOption) : trigger}
      {...restOfProps}
    >
      {renderDropdownItems({ options: optionsWithSelect, size })}
    </DropdownMenu>
  );
};
