import { tailwindMerge } from '@air/tailwind-variants';
import * as RadixDialog from '@radix-ui/react-dialog';

export type ActionSheetOverlayProps = RadixDialog.DialogOverlayProps;

export const ActionSheetOverlay = ({ className, ...restOfProps }: ActionSheetOverlayProps) => {
  return (
    <RadixDialog.Overlay
      className={tailwindMerge('fixed inset-0 bg-pigeon-900/60', className)}
      data-testid="ACTION_SHEET_OVERLAY"
      {...restOfProps}
    />
  );
};
