import { tailwindMerge } from '@air/tailwind-variants';
import { ComponentProps, memo } from 'react';

import { getExtStyles } from '~/utils/FileExtensionUtils';

interface ImageContainerProps extends ComponentProps<'div'> {
  borderRadius?: number;
  processing?: boolean;
  ext?: string;
  color?: 'gray' | 'color' | 'default';
}

export const ImageContainer = memo(
  ({ className, style, borderRadius, processing, ext, color, ...props }: ImageContainerProps) => (
    <div
      className={tailwindMerge(
        'relative flex h-full grow flex-col items-center justify-center overflow-hidden',
        processing && 'shimmer-background',
        className,
      )}
      style={{
        borderRadius,
        backgroundColor: color === 'default' || !ext ? 'var(--colors-grey4)' : getExtStyles(ext).bgColor,
        ...style,
      }}
      {...props}
    />
  ),
);

ImageContainer.displayName = 'ImageContainer';
