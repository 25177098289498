import { Calendar, Close } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { Input, InputProps } from '@air/primitive-input';
import { tailwindMerge } from '@air/tailwind-variants';
import { forwardRef, MouseEvent } from 'react';

export interface DateInputFieldProps extends InputProps {
  onClear?: (e: MouseEvent<HTMLButtonElement>) => void;
  showCalendarIcon?: boolean;
}

export const DateInputField = forwardRef<HTMLInputElement | null, DateInputFieldProps>(
  ({ showCalendarIcon = true, className, size, onClear, ...props }: DateInputFieldProps, ref) => {
    const showClearIcon = !!onClear && !!props.value;

    return (
      <div className="relative flex">
        {showCalendarIcon && <Calendar className="absolute left-3 top-1/2 size-4 -translate-y-1/2 text-grey-11" />}
        <Input
          ref={ref}
          className={tailwindMerge('w-full', showCalendarIcon ? 'pl-9' : '', showClearIcon ? 'pr-8' : '', className)}
          size={size}
          {...props}
        />
        {showClearIcon && (
          <IconButton
            label="Clear"
            appearance="ghost"
            color="grey"
            icon={Close}
            size="small"
            onClick={onClear}
            className="absolute right-1.5 top-1/2 -translate-y-1/2"
          />
        )}
      </div>
    );
  },
);

DateInputField.displayName = 'DateInputField';
