import { Close } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { tailwindMerge } from '@air/tailwind-variants';
import * as RadixDialog from '@radix-ui/react-dialog';
import { type ComponentProps } from 'react';

export type ActionSheetHeaderProps = ComponentProps<'header'> & {
  onClose?: () => void;
};

export const ActionSheetHeader = ({ children, className, onClose, ...restOfProps }: ActionSheetHeaderProps) => {
  return (
    <header
      className={tailwindMerge(
        '-mx-2 -mt-2 flex h-14 shrink-0 items-center justify-between gap-2 border-b border-b-grey-5 px-4',
        className,
      )}
      {...restOfProps}
    >
      <RadixDialog.Title className="text-16 font-semibold text-grey-11">{children}</RadixDialog.Title>

      {!!onClose && (
        <IconButton appearance="ghost" color="grey" icon={Close} label="Close" onClick={onClose} size="medium" />
      )}
    </header>
  );
};
