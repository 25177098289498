import { reducer as permissions } from '@air/redux-permissions';
import { reducer as selectedItems } from '@air/redux-selected-items';
import { reducer as uploader } from '@air/redux-uploader';
import { routerReducer } from 'connected-next-router';
import { combineReducers } from 'redux';

import { reducer as boardCollection } from '~/store/boardCollection/reducer';
import { reducer as filters } from '~/store/filters/reducer';
import { reducer as privateUploadMetadata } from '~/store/privateUploadMetadata/reducer';
import { reducer as publicUploadMetadata } from '~/store/publicUploadMetadata/reducer';

import centralizedBoard from './centralizedBoard/reducer';
import centralizedClip from './centralizedClip/reducer';
import { reducer as configViews } from './configViews/reducer';
import { reducer as kanbanManager } from './kanbanManager/reducer';
import selectedTags from './selectedTags/reducer';
import { reducer as tasks } from './tasks/reducer';
import { AirState } from './types';
import { reducer as uploadingPathToBoardId } from './uploadingPathToBoardId/reducer';

const createAppReducer = () =>
  combineReducers<AirState>({
    boardCollection,
    centralizedClip,
    centralizedBoard,
    configViews,
    filters,
    permissions,
    privateUploadMetadata,
    publicUploadMetadata,
    kanbanManager,
    router: routerReducer,
    selectedItems,
    selectedTags,
    uploader,
    tasks,
    uploadingPathToBoardId,
  });

export default createAppReducer;
